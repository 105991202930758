import { Controller } from "@hotwired/stimulus"

import consumer from "../channels/consumer"


export default class extends Controller {
    static targets = ["notifications"];


    audio;
    connect() {

        this.audio = new Audio('https://app.ukcil.com/pop.mp3');
        this.subscription = consumer.subscriptions.create(
            {
                channel: "NotificationsChannel",
                id: this.data.get("id"),
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._received.bind(this)
            }
        );

        window.vapidPublicKey = new Uint8Array(this.data.get("pk").slice(1, this.data.get("pk").length - 1).split(',').flatMap((x) => Number(x)));
        let subURL = "/home/account/" + this.data.get("id") + "/web_push_subscription"
        if ((navigator.serviceWorker) && ('PushManager' in window)) {
            navigator.serviceWorker.register(this.data.get("reg")).then(function (reg) {
                navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
                    serviceWorkerRegistration.pushManager
                        .subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: window.vapidPublicKey
                        }).then(async function (sub) {
                        const data = await fetch(subURL, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(sub),
                        }).then(r => r.json());
                    });
                });
            });
        } else {
            console.error('Service Worker is not supported in this browser');
        }

    }

    _connected() {

    }

    _disconnected() {

    }

    _received(data) {
        if (data.action == 'add') {
            this.notificationsTarget.innerHTML = data.html + this.notificationsTarget.innerHTML;
            this.audio.play();
        }
    }

    // Function to handle "x" button click for individual notifications
    hideAndMarkAsRead(event) {
        event.preventDefault();
        const notificationElement = event.currentTarget.closest('.notification');

        // Hide the notification element
        if (notificationElement) {
            notificationElement.classList.add('hidden');
        }

        // Make the HTTP request to mark the notification as read
        fetch(event.currentTarget.href, {
            method: "PUT",
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });
    }

    // Function to handle "Dismiss All" button click
    removeAllNotifications() {
        this.notificationsTarget.innerHTML = "";
    }
}