// src/controllers/clipboard_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "source" ]

    connect() {
        super.connect()

    }

    copy(event) {
        // console.log(this.sourceTarget.value)
        event.preventDefault()
        navigator.clipboard.writeText(this.sourceTarget.value)
    }
}