import { Application } from "@hotwired/stimulus"
const application = Application.start()

const environment = document.querySelector('meta[name="environment"]')?.content;
// Configure Stimulus development experience
if (environment === "development") {
    console.log("Development Debugging Enable")
    application.debug = true;
    application.warnings = true;
} else {
    application.debug = false;
    application.warnings = false;
}
window.Stimulus      = application

export { application }
