import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


export default class extends Controller {
    static values = {
        data: String,
        options: String,
        chartType: String,

    }

    connect() {
     //   console.log('connected');

        if (this.chart) {
            this.chart.destroy(); // Destroy the previous chart instance
        }

        Chart.defaults.set('plugins.datalabels', {
            display: (context) => context.dataset.data[context.dataIndex] !== 0
        });

        const ctx = this.element;

        this.chart = new Chart(ctx, {
            plugins: [ChartDataLabels],
            type: this.chartTypeValue,
            data: JSON.parse(this.dataValue),
            options: JSON.parse(this.optionsValue),
        });

        // console.log(JSON.parse(this.optionsValue));
    }
}

