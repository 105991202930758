import {Controller} from "@hotwired/stimulus"
import 'whatwg-fetch'
import cleanArray from 'clean-array'


export default class extends Controller {

    static targets = ['search', 'listContainer', 'list', 'line1', 'line2', 'line3', 'town', 'county', 'postcode', 'thoroughfare', 'building_name', 'building_number', 'sub_building_name', 'lg', 'lt', 'errorMessage']
    connect() {
    }

    search(e) {
        e.preventDefault()

        if (!this.postcodeTarget.value.length > 5) return

        fetch(this.url)
            .then((response) => {
                if (response.status >= 200 && response.status <= 300) {
                    return response
                } else {
                    let error = new Error(response.statusText)
                    error.response = response
                    throw error
                }
            })
            .then((response) => response.json())
            .then((json) => {
                this.searchTarget.value = json.postcode
                this.coordinates = {latitude: json.latitude, longitude: json.longitude}
                this.options = json.addresses
                this.listContainerTarget.classList.remove('hidden')
                this.errorMessageTarget.classList.add('hidden')
            }).catch((error) => {

            this.listContainerTarget.hidden = true
            this.errorMessageTarget.classList.remove('hidden')
            this.errorMessageTarget.innerHTML = '<div class="bg-red-50 text-red-800">'+error+'</div>'
        })


    }


    set_address() {

        var address = JSON.parse(this.listTarget.options[this.listTarget.selectedIndex].dataset.address)

        this.line1Target.value = address.line_1
        this.line2Target.value = address.line_2

        this.townTarget.value = address.town_or_city
        this.postcodeTarget.value = this.searchPostcode()
        if (this.hasline3Target) {
            this.line3Target.value = address.line_3
        } else {
           this.line3Target.value = address.locality
        }

        if (this.hasCountyTarget) {
            this.countyTarget.value = address.county
        }
        if (this.hasLatitudeTarget) {
            this.latitudeTarget.value = this.coordinates.latitude
        }
        if (this.hasLongitudeTarget) {
            this.longitudeTarget.value = this.coordinates.longitude
        }
    }

    fill(e) {
        let address = this.listTarget.options[this.listTarget.selectedIndex].dataset.address
        if (!address) return
        this.listContainerTarget.hidden = true
        this.address = JSON.parse(address)
    }


    set options(addresses) {
        this.listTarget.innerHTML = ''
        this.listTarget.appendChild(new Option('Select your address'))
        for (const address of addresses) {
            let cleanAddress = cleanArray(address.formatted_address)
            let option = new Option(cleanAddress.join(', '))
            option.dataset.address = JSON.stringify(address)
            this.listTarget.appendChild(option)
        }
        this.listContainerTarget.hidden = false

        this.errorMessageTarget.textContent = ''
    }


    searchPostcode() {
        return this.searchTarget.value
    }

    get apiKey() {
        return this.element.dataset.apikey
    }


    get url() {
        return `https://api.getaddress.io/find/${this.searchPostcode()}?api-key=${this.apiKey}&expand=true&sort=true`
    }


}