import {Controller} from "@hotwired/stimulus"
import {DateTime, easepick} from '@easepick/bundle';
import {AmpPlugin} from '@easepick/bundle';
import {TimePlugin} from '@easepick/bundle';

export default class extends Controller {

    static values = {
        time: String,
        date: String,
        option: String,
        minDate: String,
        title: String
    }

    connect() {

        if (this.optionValue === "DateTime") {
            this.dateTimePicker();
        } else {
            this.datePicker();
        }
    }

    datePicker() {
        const picker = new easepick.create({
            element: this.element,
            css: [
                "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"
            ],
            date: this.dateValue,
            zIndex: 10,
            format: "DD MMM YYYY",
            header: this.titleValue,
            plugins: [AmpPlugin],
            AmpPlugin: {
                dropdown: {
                    months: true,
                    years: true,
                    minYear: 1900
                }
            }
        });

        this.datepicker = picker;

    }

    dateTimePicker() {
        const picker = new easepick.create({
            element: this.element,
            css: [
                "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"
            ],
            date: this.dateValue,
            zIndex: 10,
            format: "DD MMM YYYY @ HH:mm",
            header: this.titleValue,
            plugins: [AmpPlugin, TimePlugin],
            TimePlugin: {
                format12: false
            },
            AmpPlugin: {
                dropdown: {
                    months: true,
                    years: true,
                    minYear: 1900
                }
            }
        });

        this.datepicker = picker;
        this.datepicker.setTime(this.timeValue)
    }


    parseDate(dateString) {
        if (!dateString) {
            return null;
        }

        const [day, month, year, time] = dateString.split(" ");
        const [hours, minutes] = time.split(":");

        const months = {
            JAN: 0,
            FEB: 1,
            MAR: 2,
            APR: 3,
            MAY: 4,
            JUN: 5,
            JUL: 6,
            AUG: 7,
            SEP: 8,
            OCT: 9,
            NOV: 10,
            DEC: 11
        };

        return new Date(
            parseInt(year),
            months[month.toUpperCase()],
            parseInt(day),
            parseInt(hours),
            parseInt(minutes)
        );
    }
    disconnect() {
        this.datepicker.destroy();
        this.datepicker = undefined;
    }

}