
import RevealController from '@stimulus-components/reveal'
export default class extends RevealController {
   static targets = ['chevron']
    static values = { open: Boolean }

    connect() {
       super.connect()
        if (this.openValue) {
            this.toggle();
        }
    }

    toggle(e) {
       super.toggle();
        if (this.hasChevronTarget) {
            this.chevronTarget.classList.toggle('rotate-180')
        }
    }
}