import { Controller } from "@hotwired/stimulus"
import {useIntersection} from 'stimulus-use'
import consumer from "../channels/consumer"


export default class extends Controller {
    static values = {
        participants: [Object],
        slug: String
    }
    static targets = ["typingMessage", "typing", "messageWindow", "input", "userType", "readMessage"];

    connect() {
        useIntersection(this)

        this.subscription = consumer.subscriptions.create(
            {
                channel: "ChatChannel",
                id: this.data.get("id"),
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._received.bind(this)
            }
        );
        this.showReadStatusAll()

    }

    _connected() {

    }

    _disconnected() {

    }

    _received(data) {

        if (data.action === 'add') {
            this.add_message(data)
        }
        if (data.action === 'update') {
            console.log("received update message read status")
            console.log(data)
            this.setReadStatus(data)
        }

    }

    add_message(data) {
        //

        this.addParticipantToChat(data.participant)
        const last_message_el = document.getElementById('last_message');
        last_message_el.remove();
        if (this.data.get("user") == data.chat_user) {
            this.messageWindowTarget.innerHTML += data.my_message_html.replaceAll('~', '');
        } else {
            if ((this.data.get("userType") === 'candidate' && (data.chat_user_type) === 'employer')) {
                const t = data.other_message_html.split('~')
                t[1] = "Employer"
                this.messageWindowTarget.innerHTML += t.join(' ')
            } else {
                this.messageWindowTarget.innerHTML += data.other_message_html.replaceAll('~', '');
            }
        }
        this.messageWindowTarget.appendChild(last_message_el);
        this.setFocus();
        this.scrollDown();

    }

    typing() {
        switch (this.inputTarget.value.length) {
            case 1:
                this.typingMessageTarget.classList.remove('hidden')
                break;
            case 0:
                this.typingMessageTarget.classList.add('hidden')
                break;
            default:
                break;
        }


    }

    scrollDown() {
        const lastMessageAnchor = document.getElementById('last_message')
        lastMessageAnchor.scrollIntoView();
    }

    tabChangeScroll() {
        const lastMessageAnchor = document.getElementById('last_message')
        lastMessageAnchor.scrollIntoView();

    }

    appear(entry) {
        this.tabChangeScroll();
        this.setFocus();

    }

    setFocus() {
        this.inputTarget.focus();

    }

    readIcon(p) {

        return `<div class="flex h-4 w-4 -mx-1 hover:w-auto overflow-hidden hover:px-2  text-xs rounded-full ${p.color.replace('bg', 'text')}  ${p.color} hover:text-white items-center">
            ${p.name}
        </div>
            `
    }

    setReadStatus(data) {

        this.addParticipantToChat(data.participant)
        this.readMessageTargets.forEach(message => {
                this.addParticipantToMessage(message, data.chat_user)
                this.showReadStatus(message)
            }
        )
    }

    showReadStatus(message) {
        let r = ''
        JSON.parse(message.dataset.read).forEach((participant) => {
            if (this.participantsValue.find(obj => (obj.id === participant) != null)) {
                // console.log('From array User: '+ participant)
                // console.log('Chat User: '+this.data.get("user"))
                // console.log('Message User:'+ message.dataset.messageUser)
                if ((participant !== message.dataset.messageUser && participant !== this.data.get("user"))) {
                    r += this.readIcon(this.participantsValue.find(obj => obj.id === participant))
                }
            }
        })
        message.innerHTML = r
    }


    showReadStatusAll() {
        this.readMessageTargets.forEach(this.showReadStatus, this)
    }

    broadcastReadLastMessage() {
        console.log("broadcast last message fired in JS")
        this.subscription.perform("read_by", {
            chat: this.data.get("id"),
            owner: this.data.get("ownerId"),
            user: this.data.get("user")
        })
    }

    addParticipantToMessage(message, participant_id) {
        let updated = JSON.parse(message.dataset.read)
        if (!updated.includes(participant_id)) {
            updated.push(participant_id);
            message.dataset.read = JSON.stringify(updated)
        }

    }

    addParticipantToChat(participant) {
        if (!this.participantsValue.includes(participant)) {
            this.participantsValue.push(participant);
        }

    }

}